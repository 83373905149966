import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { connect, FormikProps, FormikValues } from 'formik';
import get from 'lodash/get';
import React, { FocusEventHandler } from 'react';
import { ControllerProps } from 'react-hook-form';
import { useIntl } from 'react-intl';

// TODO design-system: refactor !important styles after migration to new typography APP-1443
import DatePicker from './';

dayjs.extend(customParseFormat);

// value and onChange are set manually
type Props = Omit<
  Parameters<ControllerProps['render']>['0']['field'],
  'onBlur' | 'ref' | 'value' | 'onChange'
> & {
  disableInput?: boolean;
  className?: string;
  variant?: 'dialog' | 'inline' | 'static';
  onChange?: (date: Dayjs) => void;
  dateFormatVariant?: 'short' | 'long';
  // support external value and error for non-formik usage
  externalError?: string;
  externalValue?: Date;
  label?: string;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

interface IOuterProps {
  formik: FormikProps<FormikValues>;
}

const FormikDatePicker: React.FunctionComponent<Props & IOuterProps> = ({
  formik,
  disableInput,
  variant,
  onChange,
  externalError,
  externalValue,
  ...rest
}) => {
  const i18n = useIntl();
  const id = rest.name || '';
  const error = get(formik, `errors.${id}`, false) || externalError;
  const touched = get(formik, `touched.${id}`, false) || externalError;
  const hasError = !!(touched && error);
  const value = get(formik, `values.${id}`) || externalValue || null;

  return (
    <>
      <DatePicker
        variant={variant}
        disableInput={disableInput}
        // Disable handleBlur in picker mode
        onBlur={disableInput ? undefined : formik?.handleBlur}
        error={hasError}
        value={value}
        formik={formik}
        onChange={(e) => {
          const currentValue = e.target.value;
          const isDateValid = dayjs(currentValue, 'DD.MM.YYYY').isValid();

          const dateValue = isDateValid
            ? dayjs(currentValue, 'DD.MM.YYYY').toDate()
            : currentValue;
          formik?.setFieldValue?.(id, dateValue);
          if (onChange) onChange(dateValue);
        }}
        name={rest.name}
        {...rest}
        label={String(rest.label)}
      />
      {hasError && (
        <p className="body-12-regular text-red mt-2">
          {i18n.formatMessage({
            id: error?.includes('Invalid Date') ? 'yup.validDate' : error,
          })}
        </p>
      )}
    </>
  );
};

export default connect<Props, FormikValues>(FormikDatePicker);
