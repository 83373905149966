import { Input } from '@finn/design-system/atoms/input';
import { useCurrentLocale } from '@finn/ui-utils';
import { FormikProps } from 'formik';
import React, { FocusEventHandler, Ref } from 'react';
import { ControllerProps } from 'react-hook-form';
import InputMask from 'react-input-mask';

type CustomDatePickerProps = {
  formik?: FormikProps<any>;
  disableInput?: boolean;
  variant?: 'dialog' | 'inline' | 'static';
  dateFormatVariant?: 'short' | 'long';
  disabled?: boolean;
  label?: string;
  error?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  ref?: Ref<HTMLDivElement>;
};

const CustomDatePicker: React.FunctionComponent<
  Omit<Parameters<ControllerProps['render']>['0']['field'], 'onBlur' | 'ref'> &
    CustomDatePickerProps
> = (props) => {
  const { locale } = useCurrentLocale();

  const formattedValue =
    props.value instanceof Date
      ? props.value.toLocaleString(locale, {
          day: '2-digit',
          year: 'numeric',
          month: '2-digit',
        })
      : props.value;

  return (
    <InputMask
      mask="99.99.9999"
      value={formattedValue}
      onChange={props.onChange}
      maskPlaceholder="_"
      disabled={props.disabled}
    >
      {(inputProps) => (
        <Input
          disabled={props.disabled}
          label={props.label}
          error={props?.error}
          name={props.name}
          {...inputProps}
        />
      )}
    </InputMask>
  );
};
export default CustomDatePicker;
