import { Button, ButtonProps } from '@finn/design-system/atoms/button';
import { cn } from '@finn/ui-utils';
import React from 'react';
import { useIntl } from 'react-intl';

type Props = ButtonProps & {
  loading?: boolean;
  title?: string;
  isConfirmationStap?: boolean;
};
const ContinueButton: React.FunctionComponent<Props> = ({
  loading,
  title,
  className,
  isConfirmationStap,
  ...rest
}) => {
  const i18n = useIntl();

  const buttonTitle =
    title ??
    i18n.formatMessage({
      id: isConfirmationStap
        ? 'checkout.pageData.confirmation_button'
        : 'checkout.pageData.next_step_button',
    });

  return (
    <Button
      data-cy="submit"
      loading={loading}
      className={cn('w-full sm:min-w-44', className)}
      size="lg"
      {...rest}
    >
      {buttonTitle}
    </Button>
  );
};
export default ContinueButton;
