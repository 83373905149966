import { Button } from '@finn/design-system/atoms/button';
import { VisibilityFilled } from '@finn/design-system/icons/visibility-filled';
import { VisibilityOffOutlined } from '@finn/design-system/icons/visibility-off-outlined';
import { PasswordInput } from '@finn/ua-auth';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { cn } from '@finn/ui-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

// TODO design-system: refactor !important styles after migration to new typography APP-1443
import { LoginScreen, useLoginStore } from './store/useLoginStore';

type Props = {
  label: string;
};

export const PasswordField: React.FC<Props> = ({ label }) => {
  const loginScreen = useLoginStore((state) => state.loginScreen);
  const serverErrorCode = useLoginStore((state) => state.serverErrorCode);
  const setLoginScreen = useLoginStore((state) => state.setLoginScreen);
  const isRegistration = loginScreen === LoginScreen.registrationForm;
  const autoFocus = !isRegistration;
  const form = useFormContext();
  const i18n = useIntl();
  const [didFocus, setDidFocus] = useState(autoFocus);
  const [isPasswordRevealed, setIsPasswordRevealed] = useState(false);
  const [shouldShowShowHideIcon, setShouldShowShowHideIcon] = useState(false);
  const password = form.watch('password');
  const track = useTrackingStore((state) => state.track);
  const trackForgotPasswordClick = useCallback(
    (data) => {
      track(TrackingEventName.CTA_CLICKED, {
        location: 'Forgot Password',
        additionalProps: {
          authenticationMethod: 'email',
          ...(data || {}),
        },
      });
    },
    [track]
  );

  const onFocus = useCallback(() => {
    if (!didFocus) {
      setDidFocus(true);
    }
  }, [didFocus]);

  useEffect(() => {
    if (password?.length) {
      setShouldShowShowHideIcon(true);
    } else {
      setShouldShowShowHideIcon(false);
    }
  }, [password]);

  // on server error we want to show forget password link instead of show/hide icon
  useEffect(() => {
    if (serverErrorCode && !isRegistration) {
      setShouldShowShowHideIcon(false);
    }
  }, [serverErrorCode, isRegistration]);

  const onShowHideClick = useCallback(() => {
    setIsPasswordRevealed(!isPasswordRevealed);
  }, [isPasswordRevealed]);

  const onForgotPasswordClick = useCallback(() => {
    trackForgotPasswordClick({ source: 'unified-login' });
    setLoginScreen(LoginScreen.forgotPasswordForm);
  }, [setLoginScreen, trackForgotPasswordClick]);

  return (
    <div className="relative">
      <Controller
        control={form.control}
        name="password"
        render={({ field, fieldState }) => (
          <>
            <PasswordInput
              id="password"
              label={i18n.formatMessage({ id: 'userAccount.field.password' })}
              enablePasswordStrengthCheck={didFocus && isRegistration}
              type={isPasswordRevealed ? 'text' : 'password'}
              validationError={fieldState?.error?.message}
              autoFocus={autoFocus}
              onFocus={onFocus}
              {...field}
            />
            {shouldShowShowHideIcon && (
              <Button
                variant="icon"
                size="sm"
                className={cn(
                  'absolute right-2 top-3 border-transparent p-2',
                  'hover:bg-white hover:fill-black'
                )}
                onClick={onShowHideClick}
              >
                {isPasswordRevealed ? (
                  <VisibilityOffOutlined className="min-w-5" />
                ) : (
                  <VisibilityFilled className="min-w-5" />
                )}
              </Button>
            )}
            {!isRegistration && (
              <Button
                variant="action"
                size="md"
                className="pt-4"
                onClick={onForgotPasswordClick}
              >
                {label}
              </Button>
            )}
          </>
        )}
      />
    </div>
  );
};
